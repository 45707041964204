import Vue from "vue";
import Vuex from "vuex";

import auth from "./auth.module";
import htmlClass from "./htmlclass.module";
import config from "./config.module";
import breadcrumbs from "./breadcrumbs.module";
import profile from "./profile.module";
import project from "./project.module";
import projectManager from "./projectManager.module";
import client from "./client.module";
import sidebar from "./sidebarWidth";

Vue.use(Vuex);

export default new Vuex.Store({
  modules: {
    auth,
    htmlClass,
    config,
    breadcrumbs,
    profile,
    projectManager,
    project,
    client,
    sidebar
  }
});
