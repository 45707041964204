// mutation types

import ApiService from "@/core/services/api.service";

export const SET_CLIENTS = "setClients";
export const SET_CLIENTS_IS_FETCHING = "setClientsIsFetching";
export const SET_CLIENTS_IS_FETCHING_FAIL = "setClientsIsFetchingFail";

// action types
export const GET_CLIENTS = "getClients";

const state = {
  clients: [],
  isFetchingClients: true,
  isFetchingClientsFail: null
};

const getters = {
  clients(state) {
    return state.clients;
  },
  isFetchingClients(state) {
    return state.isFetchingClients;
  },
  isFetchingClientsFail(state) {
    return state.isFetchingClientsFail;
  }
};

const mutations = {
  [SET_CLIENTS](state, clients) {
    state.clients = clients;
    state.isFetchingClients = false;
    state.isFetchingClientsFail = false;
  },
  [SET_CLIENTS_IS_FETCHING](state, error) {
    state.isFetchingClientsFail = error;
    state.isFetchingClients = false;
    state.clients = [];
  },
  [SET_CLIENTS_IS_FETCHING_FAIL](state, error) {
    state.isFetchingClientsFail = error;
    state.isFetchingClients = false;
    state.clients = [];
  }
};

const actions = {
  [GET_CLIENTS](context) {
    // TODO: traer proyecto del servidor
    context.commit(SET_CLIENTS_IS_FETCHING);
    ApiService.get("api/client")
      .then(({ data }) => {
        context.commit(SET_CLIENTS, data);
      })
      .catch((error) => {
        context.commit(SET_CLIENTS, error.message);
      });
  },
  [SET_CLIENTS](context, payload) {
    context.commit(SET_CLIENTS, payload);
  }
};

export default {
  state,
  actions,
  mutations,
  getters
};
