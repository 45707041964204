
// action types
export const UPDATE_SIDEBAR_WIDTH = "updateSidebarWidth";

// mutation types
export const SET_SIDEBAR_WIDTH = "setSidebarWidth";


const state = {
    
  sidebarWidth: 'default',

};

const getters = {
  isSidebarWidth(state) {
    return state.sidebarWidth;
  },
  
};

const actions = {
  
  [UPDATE_SIDEBAR_WIDTH]( {commit} , width) {
    commit('SET_SIDEBAR_WIDTH', width);
  },

};

const mutations = {
    
  SET_SIDEBAR_WIDTH(state, width) {
    state.sidebarWidth = width;
  },
  
};

export default {
  state,
  actions,
  mutations,
  getters
};
