// mutation types
export const SET_PROJECT = "setProject";
export const SET_TOUCHED_FORM = "setTouchedForm";
export const SET_SUBPROJECTS = "setSubProjects";
export const SET_COST_CENTERS = "setCenterCost";

// action types
export const GET_PROJECT = "getProject";
export const UPDATE_TOUCHED_FORM = "updateTouchedForm";
export const UPDATE_PROJECT = "updateProject";
export const UPDATE_SUBPROJECTS = "updateSubprojects";
export const UPDATE_COST_CENTERS = "updateCenterCost";

const state = {
  project: {},
  isFetching: true,
  isFetchingFail: false,

  isTochedFormProject: false,

  subProjects: [
    {
      id: 1,
      code: "123",
      detail: "Mi partida 1",
      quantity: 1,
      state: "Activo"
    },
    {
      id: 2,
      code: "1233",
      detail: "Mi partida 2",
      quantity: 2,
      state: "Activo"
    },
    {
      id: 3,
      code: "1234",
      detail: "Mi partida 3",
      quantity: 3,
      state: "Activo"
    }
  ],
  costCenters: [
    {
      id: 1,
      subproject: { code: "1233", detail: "Mi partida 2", quantity: 2 },
      subprojectBudge: {
        cost: "DIRECTO",
        destiny: "FABRICACIÓN",
        codePP: "919139319"
      },
      costCenterValue: "91391393",
      costCenterDetail: "Materiaules"
    },
    {
      id: 2,
      subproject: { code: "1233", detail: "Mi partida 5", quantity: 1 },
      subprojectBudge: {
        cost: "DIRECTO",
        destiny: "FABRICACIÓN",
        codePP: "919139319"
      },
      costCenterValue: "91391393",
      costCenterDetail: "Materiaules"
    },
    {
      id: 3,
      subproject: { code: "133113", detail: "Mi partida 3", quantity: 1 },
      subprojectBudge: {
        cost: "DIRECTO",
        destiny: "FABRICACIÓN",
        codePP: "919139319"
      },
      costCenterValue: "91391393",
      costCenterDetail: "Materiaules"
    }
  ]
};

const getters = {
  project(state) {
    return state.project;
  },
  isFetching(state) {
    return state.isFetching;
  },
  isFetchingFail(state) {
    return state.isFetchingFail;
  },
  isTochedFormProject(state) {
    return state.isTochedFormProject;
  },
  subProjects(state) {
    return state.subProjects;
  },
  costCenters(state) {
    return state.costCenters;
  }
};

const mutations = {
  [SET_PROJECT](state, payload) {
    state.project = payload;
  },
  [SET_TOUCHED_FORM](state, payload) {
    state.isTochedFormProject = payload;
  },
  [SET_SUBPROJECTS](state, payload) {
    state.subProjects = payload;
  },
  [SET_COST_CENTERS](state, payload) {
    state.costCenters = payload;
  }
};

const actions = {
  [GET_PROJECT](context, payload) {
    // TODO: traer proyecto del servidor
    context.commit(SET_PROJECT, payload);
  },
  [UPDATE_PROJECT](context, payload) {
    context.commit(SET_PROJECT, payload);
  },
  [UPDATE_TOUCHED_FORM](context, payload) {
    context.commit(SET_TOUCHED_FORM, payload);
  },
  [UPDATE_SUBPROJECTS](context, payload) {
    context.commit(SET_SUBPROJECTS, payload);
  },
  [UPDATE_COST_CENTERS](context, payload) {
    context.commit(SET_COST_CENTERS, payload);
  }
};

export default {
  state,
  actions,
  mutations,
  getters
};
