const PROFILE_TOKEN_KEY = "id_profile";

export const getProfile = () => {
  return JSON.parse(window.localStorage.getItem(PROFILE_TOKEN_KEY));
};

export const saveProfile = profile => {
  window.localStorage.setItem(PROFILE_TOKEN_KEY, JSON.stringify(profile));
};

export const destroyProfile = () => {
  window.localStorage.removeItem(PROFILE_TOKEN_KEY);
};

export default {
  getProfile,
  saveProfile,
  destroyProfile
};
