import Vue from "vue";
import Router from "vue-router";

Vue.use(Router);

import Dashboard from "./view/pages/Dashboard";

export default new Router({
  routes: [
    {
      path: "/",
      component: () => import("@/view/pages/auth/login_pages/Login-2"),
      children: [
        {
          name: "login",
          path: "/login"
        },
        {
          name: "register",
          path: "/register"
        }
      ]
    },
    {
      path: "/recover-password",
      name: "recover_password",
      component: () => import("@/view/pages/auth/login_pages/RecoverPassword")
    },
    {
      path: "/login2",
      component: () => import("@/view/pages/auth/login_pages/Login-2")
    },
    {
      path: "/",
      component: () => import("@/view/layout/Layout"),
      children: [
        {
          path: "/dashboard",
          name: "dashboard",
          component: Dashboard
        },
        {
          path: "/builder",
          name: "builder",
          component: () => import("@/view/pages/Builder.vue")
        },
        {
          path: "/vue-bootstrap",
          name: "vue-bootstrap",
          component: () =>
            import("@/view/pages/vue-bootstrap/VueBootstrap.vue"),
          children: [
            {
              path: "alert",
              name: "vue-bootstrap-alert",
              component: () => import("@/view/pages/vue-bootstrap/Alert.vue")
            },
            {
              path: "badge",
              name: "vue-bootstrap-badge",
              component: () => import("@/view/pages/vue-bootstrap/Badge.vue")
            },
            {
              path: "button",
              name: "vue-bootstrap-button",
              component: () => import("@/view/pages/vue-bootstrap/Button.vue")
            },
            {
              path: "button-group",
              name: "vue-bootstrap-button-group",
              component: () =>
                import("@/view/pages/vue-bootstrap/ButtonGroup.vue")
            },
            {
              path: "button-toolbar",
              name: "vue-bootstrap-button-toolbar",
              component: () =>
                import("@/view/pages/vue-bootstrap/ButtonToolbar.vue")
            },
            {
              path: "card",
              name: "vue-bootstrap-card",
              component: () => import("@/view/pages/vue-bootstrap/Card.vue")
            },
            {
              path: "carousel",
              name: "vue-bootstrap-carousel",
              component: () => import("@/view/pages/vue-bootstrap/Carousel.vue")
            },
            {
              path: "collapse",
              name: "vue-bootstrap-collapse",
              component: () => import("@/view/pages/vue-bootstrap/Collapse.vue")
            },
            {
              path: "dropdown",
              name: "vue-bootstrap-dropdown",
              component: () => import("@/view/pages/vue-bootstrap/Dropdown.vue")
            },
            {
              path: "embed",
              name: "vue-bootstrap-embed",
              component: () => import("@/view/pages/vue-bootstrap/Embed.vue")
            },
            {
              path: "form",
              name: "vue-bootstrap-form",
              component: () => import("@/view/pages/vue-bootstrap/Form.vue")
            },
            {
              path: "form-checkbox",
              name: "vue-bootstrap-form-checkbox",
              component: () =>
                import("@/view/pages/vue-bootstrap/FormCheckbox.vue")
            },
            {
              path: "form-file",
              name: "vue-bootstrap-form-file",
              component: () => import("@/view/pages/vue-bootstrap/FormFile.vue")
            },
            {
              path: "form-group",
              name: "vue-bootstrap-form-group",
              component: () =>
                import("@/view/pages/vue-bootstrap/FormGroup.vue")
            },
            {
              path: "form-input",
              name: "vue-bootstrap-form-input",
              component: () =>
                import("@/view/pages/vue-bootstrap/FormInput.vue")
            },
            {
              path: "form-radio",
              name: "vue-bootstrap-form-radio",
              component: () =>
                import("@/view/pages/vue-bootstrap/FormRadio.vue")
            },
            {
              path: "form-select",
              name: "vue-bootstrap-form-select",
              component: () =>
                import("@/view/pages/vue-bootstrap/FormSelect.vue")
            },
            {
              path: "form-textarea",
              name: "vue-bootstrap-form-textarea",
              component: () =>
                import("@/view/pages/vue-bootstrap/FormTextarea.vue")
            },
            {
              path: "image",
              name: "vue-bootstrap-image",
              component: () => import("@/view/pages/vue-bootstrap/Image.vue")
            },
            {
              path: "input-group",
              name: "vue-bootstrap-input-group",
              component: () =>
                import("@/view/pages/vue-bootstrap/InputGroup.vue")
            },
            {
              path: "jumbotron",
              name: "vue-bootstrap-jumbotron",
              component: () =>
                import("@/view/pages/vue-bootstrap/Jumbotron.vue")
            },
            {
              path: "layout-grid-system",
              name: "vue-bootstrap-layout-grid-system",
              component: () =>
                import("@/view/pages/vue-bootstrap/LayoutGridSystem.vue")
            },
            {
              path: "link",
              name: "vue-bootstrap-link",
              component: () => import("@/view/pages/vue-bootstrap/Link.vue")
            },
            {
              path: "list-group",
              name: "vue-bootstrap-list-group",
              component: () =>
                import("@/view/pages/vue-bootstrap/ListGroup.vue")
            },
            {
              path: "media",
              name: "vue-bootstrap-media",
              component: () => import("@/view/pages/vue-bootstrap/Media.vue")
            },
            {
              path: "modal",
              name: "vue-bootstrap-modal",
              component: () => import("@/view/pages/vue-bootstrap/Modal.vue")
            },
            {
              path: "nav",
              name: "vue-bootstrap-nav",
              component: () => import("@/view/pages/vue-bootstrap/Nav.vue")
            },
            {
              path: "navbar",
              name: "vue-bootstrap-navbar",
              component: () => import("@/view/pages/vue-bootstrap/Navbar.vue")
            },
            {
              path: "pagination",
              name: "vue-bootstrap-pagination",
              component: () =>
                import("@/view/pages/vue-bootstrap/Pagination.vue")
            },
            {
              path: "pagination-nav",
              name: "vue-bootstrap-pagination-nav",
              component: () =>
                import("@/view/pages/vue-bootstrap/PaginationNav.vue")
            },
            {
              path: "notify-popover",
              name: "vue-bootstrap-popover",
              component: () => import("@/view/pages/vue-bootstrap/Popover.vue")
            },
            {
              path: "notify-toasts",
              name: "vue-bootstrap-toasts",
              component: () => import("@/view/pages/vue-bootstrap/Toasts.vue")
            },
            {
              path: "notify-tooltip",
              name: "vue-bootstrap-tooltip",
              component: () => import("@/view/pages/vue-bootstrap/Tooltip.vue")
            },
            {
              path: "progress",
              name: "vue-bootstrap-progress",
              component: () => import("@/view/pages/vue-bootstrap/Progress.vue")
            },
            {
              path: "progress-spinner",
              name: "vue-bootstrap-spinner",
              component: () => import("@/view/pages/vue-bootstrap/Spinner.vue")
            },
            {
              path: "table",
              name: "vue-bootstrap-table",
              component: () => import("@/view/pages/vue-bootstrap/Table.vue")
            },
            {
              path: "tabs",
              name: "vue-bootstrap-tabs",
              component: () => import("@/view/pages/vue-bootstrap/Tabs.vue")
            }
          ]
        },
        {
          path: "/custom-plugins",
          name: "plugins",
          component: () => import("@/view/pages/plugins/Plugins.vue"),
          children: [
            {
              path: "cropper",
              name: "cropper",
              component: () => import("@/view/pages/plugins/Cropper.vue")
            },
            {
              path: "treeselect",
              name: "treeselect",
              component: () => import("@/view/pages/plugins/Treeselect.vue")
            }
          ]
        },
        {
          path: "/custom-pages",
          name: "custom-pages",
          component: () => import("@/view/pages/custom_pages/CustomPages.vue"),
          children: [
            {
              path: "profile",
              name: "profile",
              component: () => import("@/view/pages/custom_pages/Profile.vue")
            }
          ]
        }
      ]
    },
    {
      path: "/masters",
      component: () => import("@/view/layout/Layout"),
      children: [
        {
          name: "maintenances_color",
          path: "color/list",
          component: () => import("@/view/pages/maintenances/color/List.vue")
        },
        {
          name: "maintenances_measure_unit",
          path: "measure-unit/list",
          component: () =>
            import("@/view/pages/maintenances/measure_unit/List.vue")
        },
        {
          name: "maintenances_equivalent_unit",
          path: "equivalent-unit/list",
          component: () =>
            import("@/view/pages/maintenances/equivalent_unit/List.vue")
        },
        {
          name: "maintenances_unit_type",
          path: "unit-type/list",
          component: () =>
            import("@/view/pages/maintenances/unit_type/List.vue")
        },
        {
          name: "maintenances_brand",
          path: "brand/list",
          component: () => import("@/view/pages/maintenances/brand/List.vue")
        },
      ]
    },
    {
      path: "/materials",
      component: () => import("@/view/layout/Layout"),
      children: [
        {
          name: "maintenances_materials_master",
          path: "materials-master/list",
          component: () =>
            import("@/view/pages/maintenances/materials_master/List.vue")
        },
      ]
    },
    {
      path: "/customer-quote",
      component: () => import("@/view/layout/Layout"),
      children: [
        // QUOTE REQUEST
        {
          name: "maintenances_quote_request_create",
          path: "quote-request/create",
          component: () =>
            import("@/view/pages/maintenances/quote_request/Create.vue")
        },
        {
          name: "maintenances_quote_request_edit",
          path: "quote-request/:id/edit",
          component: () =>
            import("@/view/pages/maintenances/quote_request/Edit.vue")
        },
        {
          name: "maintenances_quote_request_list",
          path: "quote-request/list",
          component: () =>
            import("@/view/pages/maintenances/quote_request/List.vue")
        }
      ]
    },
    {
      path: "/maintenances",
      component: () => import("@/view/layout/Layout"),
      children: [
        {
          name: "maintenances_edit_profile",
          path: "profile/edit",
          component: () =>
            import("@/view/pages/maintenances/user/EditUserProfile.vue")
        },
        {
          name: "maintenances_category",
          path: "category/list",
          component: () => import("@/view/pages/maintenances/category/List.vue")
        },
        {
          name: "maintenances_client",
          path: "client/list",
          component: () => import("@/view/pages/maintenances/client/List.vue")
        },
        {
          name: "maintenances_item_spec_tech",
          path: "item-spec-tech/list",
          component: () =>
            import("@/view/pages/maintenances/item_spec_tech/List.vue")
        },
        {
          name: "maintenances_provider",
          path: "provider/list",
          component: () => import("@/view/pages/maintenances/provider/List.vue")
        },

        {
          name: "maintenances_subproject",
          path: "subproject/list",
          component: () =>
            import("@/view/pages/maintenances/subproject/List.vue")
        }
      ]
    },
    {
      path: "/store",
      component: () => import("@/view/layout/Layout"),
      children: [
        {
          name: "store_list",
          path: "list",
          component: () => import("@/view/pages/maintenances/store/List.vue")
        }
      ]
    },
    {
      path: "/human-resources",
      component: () => import("@/view/layout/Layout"),
      children: [
        {
          name: "maintenances_user",
          path: "user/list",
          component: () => import("@/view/pages/maintenances/user/List.vue")
        },
        {
          name: "maintenances_document_signature",
          path: "documents/nuevo",
          component: () =>
            import("@/view/pages/maintenances/documents/DocumentSignature.vue")
        },
        {
          name: "maintenances_document_list",
          path: "documents/list",
          component: () =>
            import("@/view/pages/maintenances/documents/List.vue")
        },
        {
          name: "maintenances_document_edit_signature",
          path: "documents/:id/editar",
          component: () =>
            import("@/view/pages/maintenances/documents/DocumentSignature.vue")
        },
        {
          name: "maintenances_enterprise_list",
          path: "enterprise/list",
          component: () =>
            import("@/view/pages/maintenances/enterprise/List.vue")
        },
        {
          name: "maintenances_enterprise",
          path: "enterprise/:enterprise/employees",
          component: () =>
            import(
              "@/view/pages/maintenances/enterprise/EmployeeManagement.vue"
            )
        },
        {
          name: "human_resources_boleta_de_pago",
          path: "boleta-de-pago",
          component: () =>
            import("@/view/pages/human-resources/PayrollConfiguration.vue")
        },
        {
          name: "human_resources_gratificacion",
          path: "gratificacion",
          component: () =>
            import("@/view/pages/human-resources/Gratificacion.vue")
        },
        {
          name: "human_resources_memorandum",
          path: "memorandum",
          component: () =>
            import("@/view/pages/human-resources/Memorandum.vue")
        },
        {
          name: "human_resources_contrato",
          path: "contrato",
          component: () =>
            import("@/view/pages/human-resources/Contrato.vue")
        },
        {
          name: "human_resurces_employee_payrolls",
          path: "employee-payrolls",
          component: () =>
            import("@/view/pages/human-resources/EmployeePayrolls.vue")
        },
        {
          name: "human_resurces_period",
          path: "period/list",
          component: () =>
            import("@/view/pages/human-resources/period/List.vue")
        },
        {
          name: "human_resurces_employee_documents",
          path: "employee-documents",
          component: () =>
            import("@/view/pages/human-resources/EmployeeDocuments.vue")
        }
      ]
    },
    {
      path: "/project",
      component: () => import("@/view/layout/Layout"),
      children: [
        {
          name: "project_list",
          path: "list",
          component: () => import("@/view/pages/project/List.vue")
        },
        {
          name: "project_create",
          path: "create",
          component: () => import("@/view/pages/project/Create.vue")
        },
        {
          name: "project_wizard",
          path: "wizard",
          component: () => import("@/view/pages/project/Wizard.vue")
        },
        {
          name: "project_edit",
          path: ":id/edit",
          component: () => import("@/view/pages/project/Create.vue")
        }
      ]
    },
    {
      path: "*",
      redirect: "/404"
    },
    {
      // the 404 route, when none of the above matches
      path: "/404",
      name: "404",
      component: () => import("@/view/pages/error/Error-3.vue")
    }
  ]
});
