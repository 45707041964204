// mutation types

import ApiService from "@/core/services/api.service";

export const SET_PROJECT_MANAGERS = "setProjectManagers";
export const SET_PROJECT_MANAGERS_IS_FETCHING = "setProjectManagersIsFetching";
export const SET_PROJECT_MANAGERS_IS_FETCHING_FAIL =
  "setProjectManagersIsFetchingFail";

// action types
export const GET_PROJECT_MANAGERS = "getProjectManagers";

const state = {
  projectManagers: [],
  isFetchingProjectManagers: true,
  isFetchingProjectManagersFail: null
};

const getters = {
  projectManagers(state) {
    return state.projectManagers;
  },
  isFetchingProjectManagers(state) {
    return state.isFetchingProjectManagers;
  },
  isFetchingProjectManagersFail(state) {
    return state.isFetchingProjectManagersFail;
  }
};

const mutations = {
  [SET_PROJECT_MANAGERS](state, projectManagers) {
    state.projectManagers = projectManagers;
    state.isFetchingProjectManagers = false;
    state.isFetchingProjectManagersFail = false;
  },
  [SET_PROJECT_MANAGERS_IS_FETCHING](state, error) {
    state.isFetchingProjectManagersFail = error;
    state.isFetchingProjectManagers = false;
    state.projectManagers = [];
  },
  [SET_PROJECT_MANAGERS_IS_FETCHING_FAIL](state, error) {
    state.isFetchingProjectManagersFail = error;
    state.isFetchingProjectManagers = false;
    state.projectManagers = [];
  }
};

const actions = {
  [GET_PROJECT_MANAGERS](context) {
    // TODO: traer proyecto del servidor
    context.commit(SET_PROJECT_MANAGERS_IS_FETCHING);
    ApiService.get("api/user")
      .then(({ data }) => {
        context.commit(SET_PROJECT_MANAGERS, data);
      })
      .catch((error) => {
        context.commit(SET_PROJECT_MANAGERS, error.message);
      });
  },
  [SET_PROJECT_MANAGERS](context, payload) {
    context.commit(SET_PROJECT_MANAGERS, payload);
  }
};

export default {
  state,
  actions,
  mutations,
  getters
};
