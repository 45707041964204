const ID_TOKEN_KEY = "id_token";

export const getToken = () => {
  return window.localStorage.getItem(ID_TOKEN_KEY);
};

export const saveToken = token => {
  window.localStorage.setItem(ID_TOKEN_KEY, token);
};

export const saveRefresh = refresh => {
  window.localStorage.setItem("refresh_token", refresh);
};

export const destroyToken = () => {
  window.localStorage.removeItem(ID_TOKEN_KEY);
};

export const destroyRefresh = () => {
  window.localStorage.removeItem("refresh_token");
};

export default {
  getToken,
  saveToken,
  destroyToken,
  saveRefresh,
  destroyRefresh
};
