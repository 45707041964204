import ApiService from "@/core/services/api.service";
import JwtService from "@/core/services/jwt.service";
import ProfileService from "@/core/services/profile.service";
import { SET_PERSONAL_INFO } from "@/core/services/store/profile.module";

// action types
export const VERIFY_AUTH = "verifyAuth";
export const LOGIN = "login";
export const LOGOUT = "logout";
export const REGISTER = "register";
export const UPDATE_PASSWORD = "updateUser";

// mutation types
export const PURGE_AUTH = "logOut";
export const SET_AUTH = "setUser";
export const SET_PASSWORD = "setPassword";
export const SET_ERROR = "setError";

const state = {
  errors: null,
  user: {},
  isAuthenticated: !!JwtService.getToken()
};

const getters = {
  currentUser(state) {
    return state.user;
  },
  isAuthenticated(state) {
    return state.isAuthenticated;
  }
};

const actions = {
  [LOGIN](context, credentials) {
    return new Promise(resolve => {
      ApiService.post("api/login/", credentials)
        .then(({ data }) => {
          context.commit(SET_AUTH, data);
          context.commit(SET_PERSONAL_INFO, data);
          resolve(data);
          console.log("ACABÓ LA PROMISE DEL LOGIN");
        })
        .catch(({ response }) => {
          console.log("ERROR EN PROMISE DEL LOGIN");
          context.commit(SET_ERROR, response.data.errors);
        });
    });
  },
  [LOGOUT](context) {
    console.log("LOGOUT context");
    context.commit(PURGE_AUTH);
  },
  [REGISTER](context, credentials) {
    return new Promise(resolve => {
      ApiService.post("login", credentials)
        .then(({ data }) => {
          context.commit(SET_AUTH, data);
          resolve(data);
        })
        .catch(({ response }) => {
          context.commit(SET_ERROR, response.data.errors);
        });
    });
  },
  [VERIFY_AUTH](context) {
    if (JwtService.getToken()) {
      ApiService.setHeader();
      /*ApiService.post("api/token/verify/", { token: JwtService.getToken() })
        .then(() => {
          /// console.log("TOKEN VÁLIDO");
        })
        .catch(res => {
          console.log("VERIFY_AUTH CATCH", res);
          context.commit(PURGE_AUTH);
        });*/
    } else {
      console.log(" VERIFY_AUTH NO HAY TOKEN");
      context.commit(PURGE_AUTH);
    }
  },
  [UPDATE_PASSWORD](context, payload) {
    const password = payload;

    return ApiService.put("password", password).then(({ data }) => {
      context.commit(SET_PASSWORD, data);
      return data;
    });
  }
};

const mutations = {
  [SET_ERROR](state, error) {
    console.log("SET_ERROR MUTATION");
    state.errors = error;
  },
  [SET_AUTH](state, user) {
    console.log("SET_AUTH MUTATION");
    state.isAuthenticated = true;
    state.user = user;
    state.errors = {};
    JwtService.saveToken(state.user.access);
    JwtService.saveRefresh(state.user.refresh);
    ProfileService.saveProfile(state.user);
  },
  [SET_PASSWORD](state, password) {
    state.user.password = password;
  },
  [PURGE_AUTH](state) {
    console.log("PURGE_AUTH MUTATION");
    state.isAuthenticated = false;
    state.user = {};
    state.errors = {};
    JwtService.destroyToken();
    JwtService.destroyRefresh();
  }
};

export default {
  state,
  actions,
  mutations,
  getters
};
